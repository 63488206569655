<template>
    <div class="uk-light">
        <div id="nav">
          <Navbar/>
        </div>
        <div class="backdrop">
            <div class="uk-container uk-container-small uk-padding">
                <router-view></router-view>
            </div>

            <footer class="footer uk-padding-small">
                <a href="" class="uk-divider-icon" uk-totop uk-scroll>
                    <span uk-icon="icon: chevron-up; ratio: 2"></span>
                </a>
            </footer>
        </div>
    </div>
</template>

<script>
    import Navbar from './components/Navbar.vue';

    export default {
        name: 'App',
        components: {
            Navbar,
        },
    };
</script>

<style lang="scss">
    // 1. Your custom variables and variable overwrites.
    $global-background: #1E1919;
    $navbar-background: #1E1919;
    $background-secondary-background: #1E1919;
    $inverse-global-color: #fff;
    $navbar-nav-gap: 25px;

    $mood-color-01: #ff4646;
    $mood-color-02: #ff429a;
    $mood-color-03: #d74cff;
    $mood-color-04: #7d34ff;
    $mood-color-05: #495aff;
    $mood-color-06: #2ab1ff;
    $mood-color-07: #0ee0e7;
    $mood-color-08: #05e8ae;
    $mood-color-09: #00eb35;
    $mood-color-10: #99f017;

        // 2. Import default variables and available mixins.
    @import "../node_modules/uikit/src/scss/variables-theme.scss";
    @import "../node_modules/uikit/src/scss/mixins-theme.scss";

    // 3. Your custom mixin overwrites.

    @mixin hook-inverse-label() {
        display: block;
        border-radius: 0;
        padding: 7px 0;

        span {
            background: $global-background;
            opacity: 0.8;
            color: #000;
            border-radius: 100%;
            width: 23px;
            display: block;
            aspect-ratio: 1/1;
            text-align: center;
            margin: auto;
            font-weight: 700;
        }

        //text-indent: -999999px;
        &.mood {
            &--bg {
                &-1 {
                    background-color: $mood-color-01;
                    span {
                        color: $mood-color-01;
                    }
                }
                &-2 {
                    background-color: $mood-color-02;
                    span {
                        color: $mood-color-02;
                    }
                }
                &-3 {
                    background-color: $mood-color-03;
                    span {
                        color: $mood-color-03;
                    }
                }
                &-4 {
                    background-color: $mood-color-04;
                    span {
                        color: $mood-color-04;
                    }
                }
                &-5 {
                    background-color: $mood-color-05;
                    span {
                        color: $mood-color-05;
                    }
                }
                &-6 {
                    background-color: $mood-color-06;
                    span {
                        color: $mood-color-06;
                    }
                }
                &-7 {
                    background-color: $mood-color-07;
                    span {
                        color: $mood-color-07;
                    }
                }
                &-8 {
                    background-color: $mood-color-08;
                    span {
                        color: $mood-color-08;
                    }
                }
                &-9 {
                    background-color: $mood-color-09;
                    span {
                        color: $mood-color-09;
                    }
                }
                &-10 {
                    background-color: $mood-color-10;
                    span {
                        color: $mood-color-10;
                    }
                }
            }
        }
    }

    @mixin hook-progress() {
        background-color: rgba(255,255,255,0.4);
        border-radius: 500px;
        height: 1.2em;
        position:relative;

        &:before {
            padding-left: 7px;
            content: attr(data-label);
            font-size: 0.8em;
            position:absolute;
            left:0;
            right:0;
        }

        &.mood {
            &--bg {
                &-1::-webkit-progress-value {
                    background-color: $mood-color-01;
                }
                &-2::-webkit-progress-value {
                    background-color: $mood-color-02;
                }
                &-3::-webkit-progress-value {
                    background-color: $mood-color-03;
                }
                &-4::-webkit-progress-value {
                    background-color: $mood-color-04;
                }
                &-5::-webkit-progress-value {
                    background-color: $mood-color-05;
                }
                &-6::-webkit-progress-value {
                    background-color: $mood-color-06;
                }
                &-7::-webkit-progress-value {
                    background-color: $mood-color-07;
                }
                &-8::-webkit-progress-value {
                    background-color: $mood-color-08;
                }
                &-9::-webkit-progress-value {
                    background-color: $mood-color-09;
                }
                &-10::-webkit-progress-value {
                    background-color: $mood-color-10;
                }
            }
        }
    }

    @mixin hook-progress-bar() {
        border-radius: 500px;
    }


    // 4. Import UIkit.
    @import "../node_modules/uikit/src/scss/uikit-theme.scss";

    // 5. Own SCSS Styles

    html, body {
        //background: #1E1919;
        min-height: 100vh;

        body {
            background: url(./assets/background.jpg) no-repeat center bottom fixed;
            background-size: cover;
        }

        sup {
            font-weight: 700;
        }

        .backdrop {
            background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, rgba(30, 25, 25, 0.9)), to(rgba(30, 25, 25, 0.5)));
            background: linear-gradient(rgba(30, 25, 25, 0.9) 40%, rgba(30, 25, 25, 0.5));
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            min-height: 100vh;
        }

        .vc-day-box-center-center {
            aspect-ratio: 1/1;

            &.is-not-in-month {
                //display: none;
            }
        }
        .vc-monthly .is-not-in-month * {
            opacity: 0.4;
        }

        .vc-weekdays {
            display: none;
        }

        .vc-week {
            border-top: 1px solid #fff;

            &:last-child {
                border-bottom: 1px solid #fff;
            }

            .vc-day {
                border-left: 1px solid #fff;

                &:last-child {
                    border-right: 1px solid #fff;
                }

                &.is-today {
                    background-color: rgb(255,255,255,0.18);
                }
            }
        }

        .vc-highlight-bg-solid {
            @media (max-width: $breakpoint-small) {
                width: 100% !important;
                height: 100% !important;
                border-radius: 0;
            }
        }

        .vc-day-content {
            &:hover {
                background: transparent;
            }
        }

        .vc-header {
            padding: 10px 0 0;
            margin-bottom: 20px;
        }

        .vc-arrow {
            background: transparent;
            width: 35px;
            height: 30px;
            padding: 0;

            svg {
                width: 35px;
            }

            &:hover {
                background: transparent;
            }
        }


        .uk-divider-icon.uk-totop {
            //@internal-divider-icon-image: "/path/icon-normal.svg";
            //background-image: url(images/icons/chevron-up.svg);
            display: block;
            //filter: invert(1);
            //background-size: contain;
            text-align: center;
            background-image: none;
            height: 39px;


            &:before, &:after {
                border-bottom: 1px solid rgba(255,255,255,0.6);
            }

            &>svg {
                display: none;
            }

            span svg {
                color: #fff;
            }
        }

    }
</style>
