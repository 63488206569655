<template>
  <nav class="uk-navbar-container" uk-sticky="start: 20vh;">
    <div class="uk-container">
      <div uk-navbar>

        <div class="uk-navbar-left">
          <router-link :to="{ path: '/'}" class="uk-navbar-item uk-logo"  uk-tooltip="title: Start; pos: bottom" aria-label="Startseite">
            Mood.log
          </router-link>
        </div>

        <div class="uk-navbar-right">
          <ul class="uk-navbar-nav">
            <li><router-link :to="{ path: '/calendar'}" uk-icon="icon: calendar" uk-tooltip="title: Kalender Ansicht; pos: bottom"></router-link></li>
            <li><router-link :to="{ path: '/tasks'}" uk-icon="icon: check" uk-tooltip="title: Tasks bearbeiten; pos: bottom"></router-link></li>
            <li><router-link :to="{ path: '/statistics'}" uk-icon="icon: info" uk-tooltip="title: Diagram Ansicht; pos: bottom"></router-link></li>
            <li><router-link :to="{ path: '/activities'}" uk-icon="icon: plus-circle" uk-tooltip="title: Aktivitäten bearbeiten; pos: bottom"></router-link></li>
          </ul>
        </div>

      </div>
    </div>
  </nav>
</template>

<script>
    export default {
        name: 'App',
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>