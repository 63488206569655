<template>
    <h2 class="uk-heading-line"><span>Aktivitäten</span></h2>

    <div v-for="routines in allRoutines" class="uk-grid-small" uk-grid>
        <div class="uk-width-expand" uk-leader>{{ routines.routine }}</div>
        <span class="uk-text-success" uk-icon="pencil" @click="editRoutine(index)"></span><span class="uk-text-danger" uk-icon="trash" @click="deleteRoutine(index)"></span>
    </div>

</template>

<script>
 import { getApiToken, getAllRoutines } from '@/api';
 import UIkit from 'uikit';


    export default {
        name: 'App',
        data() {
            return {
                allRoutines: [],
            };
        },
        methods:{
        },
        created() {
            getAllRoutines().then(data => {
                this.allRoutines = data;
            })
        },
        mounted() {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>