<template>
        <Calendar transparent borderless expanded trim-weeks is-dark="{}" :attributes="events" @dayclick="clickDayMood"/>

        <div class="uk-text-center uk-child-width-expand uk-grid-collapse uk-margin-medium-top" uk-grid>
                <div><span class="uk-label mood--bg-1"><span>1</span></span></div>
                <div><span class="uk-label mood--bg-2"><span>2</span></span></div>
                <div><span class="uk-label mood--bg-3"><span>3</span></span></div>
                <div><span class="uk-label mood--bg-4"><span>4</span></span></div>
                <div><span class="uk-label mood--bg-5"><span>5</span></span></div>
                <div><span class="uk-label mood--bg-6"><span>6</span></span></div>
                <div><span class="uk-label mood--bg-7"><span>7</span></span></div>
                <div><span class="uk-label mood--bg-8"><span>8</span></span></div>
                <div><span class="uk-label mood--bg-9"><span>9</span></span></div>
                <div><span class="uk-label mood--bg-10"><span>10</span></span></div>
        </div>
</template>

<script>
    import { Calendar } from 'v-calendar';
    import 'v-calendar/style.css';
    import { getAll } from '@/api';

    export default {
        name: 'App',
        components: {
            Calendar,
        },
        data() {
            return {
                moods: [],
                events: [],
                addDate: ''
            };
        },
        computed: {
        },
        methods: {
            clickDayMood: function (e) {
                const check = !!this.moods.find(mood => mood.dates === e.id);

                if (check) {
                    this.$router.push({
                        name: 'Dayview',
                        query:{date: e.id}
                    });
                }
                else {
                    this.$router.push({
                        name: 'Start',
                        query:{date: e.id}
                    });
                }
            }
        },
        created() {
            getAll().then(data => {
                let events = data;
                this.events = events;
                this.moods = events;
            });
        },
    };
</script>

<script setup>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>