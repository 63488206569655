<template>
    <h2 class="uk-heading-line"><span>Tagesübersicht</span></h2>
    <div class="uk-margin-medium">
        <div v-for="(myMood, index) in mood">
            {{ dateString }} <sup>{{ myMood.mood }} / 10</sup>
            <progress :class="'uk-progress mood--bg-' + myMood.mood" v-bind:value="myMood.mood" max="10"></progress>
        </div>
    </div>
    <hr/>
    <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-divider" uk-grid>
        <div v-for="(myRoutine, index) in routine">
            {{ myRoutine.routine }} <sup>{{ myRoutine.mood }} / 10</sup>
            <progress :class="'uk-progress mood--bg-' + myRoutine.mood" v-bind:value="myRoutine.mood" max="10"></progress>
        </div>
    </div>
</template>

<script>
 import { getApiToken, getById, getDayRoutine } from '@/api';
 import UIkit from 'uikit';


    export default {
        name: 'App',
        data() {
            return {
                mood: [],
                routine: [],
                date: '',
                dateString: ''
            };
        },
        methods:{
        },
        created() {
            getById('', this.$route.query.date).then(data => {
                this.mood = data;
                this.dateString = new Date( data[0].date ).toLocaleString('de-DE', {dateStyle: 'full'});
            });
            getDayRoutine(this.$route.query.date).then(data => {
                this.routine = data;
            });
        },
        mounted() {
            if (this.$route.query.date) {
                this.date = this.$route.query.date;
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>