<template>
    Statistiken
    <PolarArea :data="data" :options="options" />
</template>

<script>
 import { getApiToken } from '@/api';
 import UIkit from 'uikit';
 import { PolarArea } from 'vue-chartjs';
 import {
     Chart as ChartJS,
     RadialLinearScale,
     ArcElement,
     Tooltip,
     Legend
 } from 'chart.js';

 ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


 export default {
        name: 'App',
        components: { PolarArea },
        data() {
            return {
                data: {
                    labels: [
                        'Montag',
                        'Dienstag',
                        'Mittwoch',
                        'Donnerstag',
                        'Freitag',
                        'Samstag',
                        'Sonntag'
                    ],
                    datasets: [
                        {
                            label: 'My First dataset',
                            backgroundColor: 'rgba(179,181,198,0.2)',
                            pointBackgroundColor: 'rgba(179,181,198,1)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(179,181,198,1)',
                            data: [65, 59, 90, 81, 56, 55, 40]
                        },
                        {
                            label: 'My Second dataset',
                            backgroundColor: 'rgba(255,99,132,0.2)',
                            pointBackgroundColor: 'rgba(255,99,132,1)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(255,99,132,1)',
                            data: [28, 48, 40, 19, 96, 27, 100]
                        }
                    ]
                }
            }
        },
        methods:{
        },
        created() {

        },
        mounted() {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>