import { createApp } from 'vue';
import App from './App.vue';
import Start from './components/Start';
import Login from './components/Login';
import Calendar from './components/Calendar';
import Dayview from './components/Dayview';
import Statistics from './components/Statistics';
import Activities from './components/Activities';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {createRouter, createWebHistory} from 'vue-router';
import { setupCalendar } from 'v-calendar';
import { getById, getApiToken } from '@/api';

UIkit.use(Icons);

const routes = [
    {
        path: '/',
        component: Start,
        beforeEnter: (to, from, next) => {
            getById().then(data => {
                if (data.length == 0) {
                    next({ name: 'Start' })
                } else {
                    next({ name: 'Calendar' })
                }
            });
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (localStorage.hasOwnProperty('apiToken')) {
                next({ name: 'Start' })
            }
            else {
                next()
            }
        },
    },
    {
        path: '/start',
        name: 'Start',
        component: Start,
        meta: { requiresAuth: true }
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/view',
        name: 'Dayview',
        component: Dayview,
        meta: { requiresAuth: true }
    },
    {
        path: '/statistics',
            name: 'Statistics',
        component: Statistics,
        meta: { requiresAuth: true }
    },
    {
        path: '/activities',
            name: 'Activities',
        component: Activities,
        meta: { requiresAuth: true }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!(localStorage.hasOwnProperty('apiToken')) && to.meta.requiresAuth) {
        next({ name: 'Login' })
    }
    else {
        next()
    }
})

createApp(App).use(router).mount('#app');

console.log('hello human');
