<template>
    <form v-on:submit.prevent="login">
        <legend class="uk-legend uk-margin-medium"><span uk-icon="icon: sign-in"></span> Bitte melde dich an</legend>

        <div class="uk-margin">
            <div class="uk-inline uk-width-expand">
                <span class="uk-form-icon" uk-icon="icon: mail"></span>
                <input class="uk-input" type="email" aria-label="Not clickable icon" v-model="form.email" required>
            </div>
        </div>

        <div class="uk-margin">
            <div class="uk-inline uk-width-expand">
                <span class="uk-form-icon" uk-icon="icon: lock"></span>
                <input class="uk-input" type="password" aria-label="Not clickable icon" v-model="form.password" required>
            </div>
        </div>

        <div class="uk-clearfix" uk-margin>
            <button class="uk-button uk-button-default">Anmelden</button>
        </div>
    </form>
</template>

<script>
 import { getApiToken } from '@/api';
 import UIkit from 'uikit';


    export default {
        name: 'App',
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                }
            };
        },
        methods:{
            login: function (e) {
                getApiToken(this.form).then(data => {
                    if ( data.status && localStorage.hasOwnProperty('apiToken') ) {
                        this.$router.push({path: '/start', query:{token: data.token}});
                    }
                    else {
                        UIkit.notification({
                            message: '<span uk-icon=\'icon: warning\'></span> Anmeldung fehlgeschlagen!',
                            status: 'danger',
                            pos: 'bottom-center'
                        })
                    }
                })
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>