<template>
    <form v-on:submit.prevent="submit">
        <legend class="uk-legend uk-text-center uk-margin-medium">Für heute gibt es noch keinen Eintrag</legend>

        <fieldset class="uk-fieldset" v-show="formStep === 0">
            <div class="uk-grid-collapse uk-margin-medium" uk-grid>
                <div>
                    <label for="rangeSlider">Wie war dein Tag?</label>
                </div>
                <div class="uk-width-expand uk-margin-medium-left uk-margin-medium-right">
                    <input type="range" id="rangeSlider" class="uk-range" min="1" max="10" v-model="form.mood">
                </div>
                <div><label for="rangeSlider">{{ form.mood }}</label></div>
            </div>
        </fieldset>

        <fieldset class="uk-fieldset" v-show="formStep === 1">
            <div class="uk-margin-medium">
                <div class="uk-inline uk-width-1-1">
                    <span uk-icon="pencil" class="uk-form-icon"></span>
                    <input list="allRoutine" name="routine" id="routine" class="uk-input" placeholder="Aktivität" v-model="form.dayRoutine.routine">
                    <datalist id="allRoutine">
                        <option v-for="routines in allRoutines">{{ routines.routine }}</option>
                    </datalist>
                </div>

                <div class="uk-grid-collapse uk-margin-medium" uk-grid>
                    <div class="uk-width-expand uk-margin-medium-right">
                        <input type="range" id="rangeSlider2" class="uk-range" min="1" max="10" v-model="form.dayRoutine.routineMood" @change="rangeChange"/>
                    </div>
                    <div><label for="rangeSlider2">{{ form.dayRoutine.routineMood }}</label></div>
                </div>

                <h4 class="uk-heading-line uk-text-center"><button uk-icon="icon: plus-circle" v-on:click="addRoutine()"></button></h4>

                <ul class="uk-list uk-list-circle activity-list" v-for="(myRoutine, index) in form.dayRoutine">
                    <li>
                        <div class="uk-grid-collapse" uk-grid>
                            <div class="uk-width-expand">{{myRoutine.routine}} ( {{myRoutine.routineMood}} )</div>
                            <span uk-icon="trash" @click="deleteRoutine(index)"></span>
                        </div>
                    </li>
                </ul>
            </div>
        </fieldset>

        <div class="uk-clearfix" uk-margin>
            <button class="uk-button uk-button-primary uk-float-left" v-show="formStep === 0" @click="formStep++">Weiter</button>
            <button class="uk-button uk-button-default uk-float-left" v-show="formStep === 1" @click="formStep--">Zurück</button>
            <button class="uk-button uk-button-primary uk-float-right" v-show="formStep === 1" @click="formStep++">Absenden</button>
            <button class="uk-button uk-button-default uk-float-right" v-show="formStep === 0" @click="formStep++" name="jump">Überspringen</button>
        </div>
    </form>
</template>

<script>
 import { getById, postMood, getAllRoutines } from '@/api';

 const date = new Date();
 const dateIso = date.toISOString().slice(0, 10);
 var token = false;

    export default {
        name: 'App',
        data() {
            return {
                allRoutines: [],
                length: 0,
                formStep: 0,
                form: {
                    mood: 1,
                    date: dateIso,
                    dayRoutine: []
                }
            };
        },
        methods: {
            deleteRoutine: function (index) {
                this.form.dayRoutine.splice(index, 1);
            },
            rangeChange: function (e) {
                this.range = this.form.dayRoutine.routineMood;
            },
            addRoutine: function (e) {
                this.form.dayRoutine.push({
                    routineMood: this.form.dayRoutine.routineMood,
                    routine: this.form.dayRoutine.routine,
                    date: this.form.date
                });
                this.form.dayRoutine.routineMood = 1;
                this.form.dayRoutine.routine = '';

                if (this.form.mood == 0) {
                    var count = this.form.dayRoutine.length,
                        total = this.form.dayRoutine.reduce( function(tot, record) {
                            return tot + parseFloat(record.routineMood);
                        },0),
                        average = Math.round(total / count);
                    this.form.mood = average;
                }
            },
            submit: function (e) {
                if (this.formStep > 1) {
                    postMood(this.form);
                    this.$router.push({
                        name: 'Calendar',
                    });
                }
                if (e.submitter.name == 'jump') {
                    this.form.mood = 0;
                }
            }
        },
        created() {
            this.form.dayRoutine.routineMood = 1;
            if (this.$route.query.token) token = this.$route.query.token;
            getById(token).then(data => {
                this.length = data.length;
            });
            getAllRoutines(token).then(data => {
                this.allRoutines = data;
            })
        },
        mounted() {
            if (this.$route.query.date) {
                this.form.date = this.$route.query.date;
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>