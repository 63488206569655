const moodColors = ['#ff4646', '#ff429a', '#d74cff', '#7d34ff', '#495aff', '#2ab1ff', '#0ee0e7', '#05e8ae', '#00eb35', '#99f017'];
const moodColorsRGB = ['255,70,70', '255,66,154', '215,76,255', '125,52,255', '73,90,255', '42,177,255', '14,224,231', '5,232,174', '0,235,53', '153,240,23'];
const date = new Date();
var dateIso = date.toISOString().slice(0, 10);
const day = date.getDate();
const year = date.getFullYear();
const month = date.getMonth();
var token = localStorage.getItem('apiToken');

// API AUTH CALL : POST to get Token
export let getApiToken = async (data) => {
    console.log('API AUTH : GET TOKEN');

    const userCredential = '?email=' + data.email + '&password=' + data.password;

    try {
        let getApiToken = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/login' + userCredential, {
            method: 'POST',
            headers: {
                'content-type': 'form-data'
            }
        });

        const apiToken = await getApiToken.json();
        localStorage.setItem('apiToken', apiToken.token);

        return apiToken;
    } catch (error) {
        console.log(error);
    }
}

// API CALL ENDPOINT: GET /api/mood
export let getAll = async (dates) => {
    console.log('API : GET ALL MOODS');

    try {
        let response = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/mood', {
            headers: {
                'authorization': 'Bearer ' + token
            },
        });
        const res = await response.json();
        const moods = res.data;
        const dates = moods.map((mood, i) => {
            const moodEntry = {
                id: mood['id'],
                highlight: {
                    style: {
                        background: 'rgba(' + moodColorsRGB[mood['mood'] - 1] + ',0.4)',
                        borderColor: moodColors[mood['mood'] - 1],
                        //opacity: '0.5',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        width: '36%',
                        height: '36%',
                        minWidth: '10px',
                        minHeight: '10px'
                    },
                    contentStyle: {
                        color: 'white'
                    }
                },
                dates: mood['date'],
                popover: {
                    label: mood['date'] + ' : ' + mood['mood'],
                    visibility: 'click',
                }
            };

        return moodEntry;
    });

        return dates;
    } catch (error) {
        console.log(error);
    }

}

// API CALL ENDPOINT: GET /api/mood/:date
export let getById = async (tmpToken, date) => {
    console.log('API : GET MOOD BY DATE');

    if (date) {
        dateIso = date;
    }

    if ( tmpToken ) {
        token = tmpToken
    }

    try {
        let response = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/mood/' + dateIso, {
            headers: {
                'authorization': 'Bearer ' + token
            },
        });
        const res = await response.json();
        const moods = res.data;
        return moods;
    } catch (error) {
        console.log(error);
    }
}

// API CALL ENDPOINT: POST /api/mood
export let postMood = async (data) => {
    console.log('API : POST MOOD RECORD');

    if (data.dayRoutine[0] === Object(data.dayRoutine[0])) {
        postDayRoutine(data.dayRoutine);
    }

    try {
        let postMood = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/mood', {
            method: 'POST',
            headers: {
                'authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                date: data.date,
                mood: data.mood
            })
        });

        const response = await postMood.json();
        console.log(response);

        return response;
    } catch (error) {
        console.log(error);
    }

}

// API CALL ENDPOINT: GET /api/routines
export let getAllRoutines = async (tmpToken) => {
    console.log('API : GET ALL ROUTINES RECORD');

    if ( tmpToken ) {
        token = tmpToken
    }

    try {
        let allRoutines = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/routines', {
            headers: {
                'authorization': 'Bearer ' + token,
            }
        });

        const res = await allRoutines.json();
        const routines = res.data;

        return routines;
    } catch (error) {
        console.log(error);
    }
}

// API CALL ENDPOINT: POST /api/routine
export let postDayRoutine = async (data) => {
    console.log('API : POST DAY ROUTINE RECORD');

    var routineBulk = [];

    data.forEach(routine => {
        routineBulk.push({
            date: routine.date,
            routine: routine.routine,
            mood: routine.routineMood
        });
    });

    try {
        let postMood = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/routine', {
            method: 'POST',
            headers: {
                'authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(routineBulk)
        });

        const response = await postMood.json();
        console.log(response);

        return response;
    } catch (error) {
        console.log(error);
    }
}

// API CALL ENDPOINT: GET /api/routine/:date
export let getDayRoutine = async (date) => {
    console.log('API : GET ROUTINES BY DATE');

    try {
        let response = await fetch(process.env.VUE_APP_API_DOMAIN + 'api/routine/' + date, {
            headers: {
                'authorization': 'Bearer ' + token
            },
        });
        const res = await response.json();
        const routine = res.data;
        return routine;
    } catch (error) {
        console.log(error);
    }
}